<template>
  <div class="flex flex-col flex-1 h-full overflow-hidden">
    <div>
      <nav class="mr-auto flex flex-wrap justify-between items-center bg-white border-b shadow px-5 py-3">
        <div class="flex justify-start items-center space-x-3">
          <Button @click="$router.push({ name: 'Design', params: { id } })" type="button" :label="$t('templateBuilder.backToDesign')" iconPos="left" icon="pi pi-arrow-left" class="p-button-light shadow" />
          <div v-if="blockId" class="flex flex-center space-x-2">
            <div class="inline-flex items-center rounded-md bg-gray-50 py-1 px-2 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-500/10">ID: <span class="pl-1 text-gray-700">{{ blockId }}</span></div>
          </div>
          <div v-if="formTemplate.name" class="template text-lg text-gray-700 font-semibold line-clamp-1">{{ formTemplate.name }}</div>
        </div>
        <div class="flex justify-end items-center space-x-3">
          <div class="p-buttonset">
            <Button class="p-button-light shadow" @click="toggleStructure(!editor.actions.structure)" v-tooltip.bottom="$t('templateBuilder.struct')">
              <Icon v-if="!editor.actions.structure" icon="tabler:border-all" class="w-5 h-5" />
              <Icon v-if="editor.actions.structure" icon="tabler:border-none" class="w-5 h-5" />
            </Button>
            <Button class="p-button-light shadow" @click="togglePreview(!editor.actions.preview)" v-tooltip.bottom="$t('templateBuilder.preview')">
              <Icon v-if="!editor.actions.preview" icon="tabler:eye" class="w-5 h-5" />
              <Icon v-if="editor.actions.preview" icon="tabler:eye-off" class="w-5 h-5" />
            </Button>
          </div>

          <Button @click="downloadTemplate" type="button" icon="pi pi-download" class="p-button-outlined p-button-primary shadow" :disabled="savingBlockTemplate" v-tooltip.bottom="$t('templateBuilder.download')" />
          <Button @click="validAndSaveTemplate" type="button" :label="$t('templateBuilder.save')" :disabled="savingBlockTemplate" class="p-button-primary shadow" />
        </div>
      </nav>
    </div>
    <div class="min-h-0 flex flex-col flex-1 h-full">
      <div id="editor-plugin-container" class="flex overflow-hidden min-h-full"></div>
    </div>

    <Dialog :header="$t('templateBuilder.saveBlock')" v-model:visible="dialog.show" position="center" modal :dismissableMask="true" :style="{ width: '30rem', padding: 0 }">
      <div class="flex flex-col">
        <form @submit.prevent="saveTemplate" @keydown="formTemplate.onKeydown($event)">
          <div class="form-group">
            <label for="name" class="form-label">
              {{ $t("templateBuilder.name") }}
              <LabelRequired />
            </label>
            <InputText v-model="formTemplate.name" id="name" name="name" type="text" class="p-inputtext-sm flex flex-1 flex-col" autofocus />
            <HasError :form="formTemplate" field="name" />
          </div>
          <div class="form-group">
            <label class="form-label">
              {{ $t("templateBuilder.category") }}
              <LabelRequired />
            </label>
            <Dropdown v-model="formTemplate.category" :options="saveRow.config.categoryOptions" :editable="true" optionLabel="name" optionValue="code" dataKey="code" class="w-full p-inputtext-sm shadow-sm" placeholder="Selecciona una categoría o agrega una nueva" />
            <label class="form-label-help mt-2" v-html="$t('templateBuilder.categoryHelpPart1')"></label>
            <label class="form-label-help mt-1" v-html="$t('templateBuilder.categoryHelpPart2')"></label>
          </div>
          <div class="form-group">
            <label for="tags" class="form-label">
              {{ $t("templateBuilder.tags") }}
            </label>
            <AutoComplete v-model="formTemplate.tags" id="tags" name="tags" class="p-inputtext-sm flex flex-1 flex-col w-full" multiple :suggestions="tags" @complete="searchTags" />
            <HasError :form="formTemplate" field="tags" />
          </div>
          <div class="form-group">
            <label for="notes" class="form-label">{{ $t("templateBuilder.notes") }}</label>
            <Textarea v-model="formTemplate.notes" rows="2" id="notes" name="notes" class="p-inputtext-sm" />
            <HasError :form="formTemplate" field="notes" />
          </div>
          <div class="flex items-center justify-end space-x-3 pt-3">
            <Button @click="dialog.show = false" type="button" :label="$t('templateBuilder.cancel')" class="p-button-light shadow" />
            <Button type="submit" :label="$t('templateBuilder.save')" :loading="savingBlockTemplate" class="p-button-primary shadow" />
          </div>
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Icon } from "@iconify/vue"
import Button from "primevue/button"
import Dropdown from "primevue/dropdown"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import Textarea from "primevue/textarea"
import AutoComplete from "primevue/autocomplete"

import ApiRoute from "@/config/ApiRoute"
import FormClient from "@/config/FormClient"
import { HasError } from "vform/src/components/tailwind"
import LabelRequired from "@/components/LabelRequired"

import { saveAs } from "file-saver"
import slugify from "slugify"

import WidgetEditorConfig from "@/config/WidgetEditorConfig"

const sprintf = require("sprintf-js").sprintf

export default {
  components: {
    Icon,
    Button,
    Dropdown,
    Dialog,
    InputText,
    Textarea,
    HasError,
    AutoComplete,
    LabelRequired
  },

  props: {
    id: String,
    blockId: {
      type: String,
      required: false
    }
  },

  data() {
    return {
      savingBlockTemplate: false,
      tags: [],
      saveRow: {
        show: false,
        resolve: null,
        reject: null,
        args: null,
        form: new FormClient({
          template_id: null,
          project_id: this.id,
          type: "emailSaveRow",
          name: "",
          category: "",
          notes: "",
          tags: [],
          structure: "",
          message: ""
        }),
        config: {
          categoryOptions: [
            { name: "Header", code: "header" },
            { name: "Content", code: "content" },
            { name: "Footer", code: "footer" },
            { name: "Products", code: "products" },
            { name: "Others", code: "others" }
          ]
        }
      },
      saveRowEdit: {
        show: false,
        resolve: null,
        reject: null,
        args: null,
        form: new FormClient({
          template_id: null,
          project_id: this.id,
          type: "emailSaveRow",
          name: "",
          category: "",
          notes: "",
          tags: []
        })
      },
      currentStep: "design",
      steps: [
        { id: "1", name: "Design", step: "design" },
        { id: "2", name: "Products", step: "products" }
      ],
      dialog: {
        show: false
      },
      formTemplate: new FormClient({
        template_id: this.blockId || null,
        project_id: this.id,
        type: "emailSaveRow",
        name: "",
        category: "",
        notes: "",
        tags: [],
        structure: "",
        message: ""
      }),
      editor: {
        instance: null,
        token: null,
        template: {},
        client: {
          http: new FormClient({
            client_id: "03db946c-ec90-40b1-a842-f3b6deecbb82",
            client_secret: "Q9muQjU17hA27ITMdhPJFBYfc9heHbgVSbO80PQraKYbD81yA3JQ",
            grant_type: "password"
          }),
          endpoint: "https://auth.getbee.io/apiauth"
        },
        actions: {
          saving: false,
          structure: false,
          preview: false,
          advancedPreview: false
        },
        config: {
          uid: `workspace-${this.id}`,
          container: "editor-plugin-container",
          language: "es-ES",
          loadingSpinnerTheme: "light",
          sidebarPosition: "right",
          defaultModulesOrder: ["Heading", "Paragraph", "Text", "Image", "Button", "Divider", "Spacer", "Social", "Html", "Video", "List", "Menu", "Icons"],
          workspace: {
            editSingleRow: true
          },
          trackChanges: true,
          saveRows: true,
          preventClose: false,
          specialLinks: [],
          mergeTags: [],
          mergeContents: [],
          contentDefaults: {},
          rowsConfiguration: {
            emptyRows: true,
            defaultRows: true,
            savedRows: true,
            externalContentURLs: []
          },
          contentDialog: {
            saveRow: {
              handler: (resolve, reject, args) => {
                this.saveRow.show = true
                this.saveRow.resolve = resolve
                this.saveRow.reject = reject
                this.saveRow.args = args

                this.saveRow.form.template_id = null
                this.saveRow.form.name = ""
                this.saveRow.form.category = ""
                this.saveRow.form.notes = ""
                this.saveRow.form.tags = []
                this.saveRow.form.structure = ""
                this.saveRow.form.message = ""
              }
            },
            onDeleteRow: {
              handler: async (resolve, reject, args) => {
                try {
                  await new FormClient().delete(sprintf(ApiRoute.template.delete, { template_id: args.row.metadata.id }))
                  resolve(true)
                  this.refreshUI()
                } catch (error) {
                  reject()
                }
              }
            },
            onEditRow: {
              handler: (resolve, reject, args) => {
                this.saveRowEdit.show = true
                this.saveRowEdit.resolve = resolve
                this.saveRowEdit.reject = reject
                this.saveRowEdit.args = args
                this.saveRowEdit.form.template_id = args.row.metadata.id
                this.saveRowEdit.form.name = args.row.metadata.name
                this.saveRowEdit.form.category = args.row.metadata.category
                this.saveRowEdit.form.notes = ""
                this.saveRowEdit.form.tags = []
              }
            }
          },

          onSaveRow: async (rowJSON, htmlFile, jsonFile) => {
            this.saveRow.form.structure = { rowJSON: JSON.parse(rowJSON), pageJSON: JSON.parse(jsonFile) }
            this.saveRow.form.message = htmlFile
            await this.saveRow.form.post(ApiRoute.template.store)
            this.refreshUI()
          },

          onSave: async (jsonFile, htmlFile) => {
            let pageJSON = JSON.parse(jsonFile)

            this.formTemplate.structure = {
              ...this.formTemplate.structure,
              pageJSON
            }

            this.formTemplate.message = htmlFile
            let templateResponse = await this.formTemplate.post(ApiRoute.template.store)
            this.formTemplate.template_id = templateResponse.data.data.id

            this.$toast.success(this.$t("Template guardado con éxito"))
            this.savingBlockTemplate = false
            this.dialog.show = false
          },

          onSend: (htmlFile) => {
            let filename = new Date().getTime()
            if (this.blockId) {
              filename = this.formTemplate.name
            }

            saveAs(new Blob([htmlFile]), `${filename}.html`)
            this.savingBlockTemplate = false
          },

          onError: (errorMessage) => {
            console.log("onError ", errorMessage)
          }
        }
      }
    }
  },

  computed: {
    showAdditionalSettings() {
      return this.productRecommendation.form.data.price || this.productRecommendation.form.data.price_sale || this.productRecommendation.form.data.button || this.productRecommendation.form.data.percentage_discount || this.productRecommendation.form.data.discount
    },

    missingProducts() {
      return this.productRecommendation.form.design.columns * this.productRecommendation.form.design.repeat - this.productRecommendation.productSelected.length
    }
  },

  mounted() {
    this.loadUI()
  },

  methods: {
    async searchTags(event) {
      let response = await new FormClient().get(sprintf(ApiRoute.tags.index) + "?keyword=" + event.query + "&project=" + this.id)
      this.tags = response.data.data.map((tag) => tag.name)
      this.tags.push(event.query)
    },

    async loadUI() {
      let template = {}
      if (this.blockId) {
        // IF WE ARE EDITING THE TEMPLATE THEM WE NEED TO SEARCH IT
        const response = await new FormClient().get(
          sprintf(ApiRoute.template.show, {
            template_id: this.blockId,
            project_id: this.id
          })
        )
        const responseTemplate = response.data.data[0]

        this.formTemplate.name = responseTemplate.name
        this.formTemplate.category = responseTemplate.category
        this.formTemplate.message = responseTemplate.message
        this.formTemplate.structure = responseTemplate.structure
        this.formTemplate.tags = responseTemplate.tags.map((tag) => tag.name)
        this.formTemplate.notes = responseTemplate.notes
        template = responseTemplate.structure.pageJSON
      } else {
        // SET BLANK TEMPLATE
        template = {
          page: {
            body: {
              container: { style: { "background-color": "#FFFFFF" } },
              content: {
                computedStyle: {
                  linkColor: "#3366BB",
                  messageBackgroundColor: "transparent",
                  messageWidth: "675px"
                },
                style: { color: "#000000", "font-family": "Arial, Helvetica Neue, Helvetica, sans-serif" }
              },
              type: "mailup-bee-page-properties",
              webFonts: [
                {
                  fontFamily: "'Montserrat', 'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
                  name: "Montserrat",
                  url: "https://fonts.googleapis.com/css?family=Montserrat"
                }
              ]
            },
            description: "Blank template",
            rows: [
              {
                type: "one-column-empty",
                container: {
                  style: {
                    "background-color": "transparent",
                    "background-image": "none",
                    "background-repeat": "no-repeat",
                    "background-position": "top left"
                  }
                },
                content: {
                  style: {
                    "background-color": "transparent",
                    color: "#000000",
                    width: "500px",
                    "background-image": "none",
                    "background-repeat": "no-repeat",
                    "background-position": "top left"
                  }
                },
                columns: [
                  {
                    "grid-columns": 12,
                    modules: [],
                    style: {
                      "background-color": "transparent",
                      "padding-top": "5px",
                      "padding-right": "0px",
                      "padding-bottom": "5px",
                      "padding-left": "0px",
                      "border-top": "0px solid transparent",
                      "border-right": "0px solid transparent",
                      "border-bottom": "0px solid transparent",
                      "border-left": "0px solid transparent"
                    },
                    uuid: "1553aef7-174a-4b21-83f1-" + new Date().getTime()
                  }
                ],
                uuid: "3ff29aee-7156-402c-b846-" + new Date().getTime()
              }
            ],
            template: { name: "template-base", type: "basic", version: "2.0.0" },
            title: "Blank template"
          },
          comments: {}
        }
      }

      try {
        // GET APPEARANCE FOR THE PROJECT
        let appearanceResponse = await new FormClient().get(sprintf(ApiRoute.project.appearance, { project_id: this.id }))
        let appearance = Object.keys(appearanceResponse.data).length ? { ...appearanceResponse.data } : { ...WidgetEditorConfig.form }
        let contentDefaults = {}

        contentDefaults.general = {
          backgroundColor: "#ffffff",
          contentAreaBackgroundColor: "#ffffff",
          defaultFont: appearance.text.styles.fontFamily,
          linkColor: appearance.text.styles.linkColor
        }

        contentDefaults.paragraph = {
          styles: this.adaptAppareanceForEditor(appearance.text.styles),
          blockOptions: this.adaptAppareanceForEditor(appearance.text.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.text.mobileStyles)
        }

        contentDefaults.text = {
          html: "Escribe algo increíble como por ejemplo...",
          styles: this.adaptAppareanceForEditor(appearance.text.styles),
          blockOptions: this.adaptAppareanceForEditor(appearance.text.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.text.mobileStyles)
        }

        contentDefaults.button = {
          label: appearance.button.label,
          styles: this.adaptAppareanceForEditor(appearance.button.styles),
          mobileStyles: this.adaptAppareanceForEditor(appearance.button.mobileStyles)
        }

        this.editor.config.contentDefaults = contentDefaults
      } catch (error) {
        console.log(error)
      }

      // GET FIELDS FOR MAILUP
      let fields = await this.getFields()
      this.editor.config.mergeTags = fields

      // GET SAVE ROWS
      this.editor.config.rowsConfiguration.externalContentURLs = await this.getGroupedCategories()

      // GET TOKEN FOR THE EDITOR PLUGIN
      const token = (await this.editor.client.http.post(this.editor.client.endpoint)).data

      // INIT THE EDITOR PLUGIN
      window.BeePlugin.create(token, this.editor.config, (pluginInstance) => {
        this.editor.instance = pluginInstance
        this.editor.instance.start(template)
      })
    },

    async refreshUI() {
      let newConfig = {
        rowsConfiguration: {
          externalContentURLs: await this.getGroupedCategories()
        }
      }

      this.editor.instance.loadConfig(newConfig)
    },

    adaptAppareanceForEditor(dataObject) {
      let newDataObject = { ...dataObject }

      Object.keys({ ...newDataObject }).forEach((dataObjectKey) => {
        let dataObjectValue = newDataObject[dataObjectKey]
        if (["fontSize", "border", "borderRadius", "paddingTop", "paddingRight", "paddingBottom", "paddingLeft", "marginTop", "marginRight", "marginBottom", "marginLeft"].indexOf(dataObjectKey) > -1) {
          dataObjectValue = `${dataObjectValue}px`
        }

        if (dataObjectKey == "border") {
          newDataObject["borderTop"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderRight"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderBottom"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
          newDataObject["borderLeft"] = `${dataObjectValue} ${newDataObject["borderStyle"]} ${newDataObject["borderColor"]}`
        }

        newDataObject[dataObjectKey] = dataObjectValue
      })

      return newDataObject
    },

    async getGroupedCategories() {
      // GET SAVE ROWS
      let groupedCategories = []

      try {
        this.saveRow.config.categoryOptions.map((category) => {
          groupedCategories.push({
            name: category.name,
            value: `${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.id}/templates/content-saved-rows?type=emailSaveRow&category=${category.code}`,
            handle: category.name,
            behaviors: {
              canEdit: true,
              canDelete: true
            }
          })
        })

        const groupedCategoriesResponse = await new FormClient().get(sprintf(ApiRoute.template.groupedCategories, { project_id: this.id }))
        groupedCategoriesResponse.data.data.map((groupedCategory) => {
          if (groupedCategory.type == "emailSaveRow") {
            if (!this.saveRow.config.categoryOptions.find((category) => category.code == groupedCategory.category)) {
              groupedCategories.push({
                name: groupedCategory.category,
                value: `${process.env.VUE_APP_URL_API}/api/2.0/projects/${this.id}/templates/content-saved-rows?type=emailSaveRow&category=${groupedCategory.category}`,
                handle: groupedCategory.category,
                behaviors: {
                  canEdit: true,
                  canDelete: true
                }
              })

              this.saveRow.config.categoryOptions.push({
                name: groupedCategory.category,
                code: groupedCategory.category
              })
            }
          }
        })
      } catch (error) {
        console.log(error)
      }

      return groupedCategories
    },

    async getFields() {
      let fields = []

      try {
        const credentialsForMailUpChannelResponse = await new FormClient().get(sprintf(ApiRoute.project.credentials, { project_id: this.id }) + "?filter[channel_id][%3D]=2")
        if (credentialsForMailUpChannelResponse.data.data.length) {
          const fieldsResponse = await new FormClient({ config: { ...(credentialsForMailUpChannelResponse.data.data[0]?.config || {}) } }).post(sprintf(ApiRoute.project.fields, { channel_id: 2 }))

          fieldsResponse.data.map((field) => {
            fields.push({
              name: field.label,
              value: "[" + slugify(field.label, "") + "]"
            })
          })
        }
      } catch (error) {
        console.log(error)
      }

      return fields
    },

    toggleStructure(value) {
      this.editor.actions.structure = value
      this.editor.instance.toggleStructure()
    },

    togglePreview(value) {
      this.editor.actions.preview = value
      this.editor.instance.togglePreview()
    },

    toggleAdvancedPreview(value) {
      this.editor.actions.advancedPreview = value
      this.editor.instance.toggleMergeTagsPreview()
    },

    validAndSaveTemplate() {
      this.dialog.show = true
    },

    downloadTemplate() {
      this.savingBlockTemplate = true
      this.editor.instance.send()
    },

    saveTemplate() {
      this.savingBlockTemplate = true
      this.editor.instance.save()
    },

    saveRowCancel() {
      this.saveRow.reject()
      this.saveRow.show = false
    },

    saveRowSave() {
      this.saveRow.resolve({
        name: this.saveRow.form.name,
        category: this.saveRow.form.category
      })
      this.saveRow.show = false
    },

    saveRowEditCancel() {
      this.saveRowEdit.reject()
      this.saveRowEdit.show = false
    },

    async saveRowEditSave() {
      try {
        await this.saveRowEdit.form.post(ApiRoute.template.store)
        this.saveRowEdit.resolve(true)
        this.refreshUI()
      } catch (error) {
        this.saveRowEdit.reject()
      }

      this.saveRowEdit.show = false
    }
  }
}
</script>
